<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">角色管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span>
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
        <button
          v-show="!grid.isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @removeSelectedRows="hideBatchDropDown()"
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
              <template #form-permissions="{ data }">
                <check-box-list
                  :columnCount="4"
                  :items="permissionItems"
                  v-model="data.PermissionValues"
                ></check-box-list>
              </template>
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button type="submit" status="primary" content="確認" @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
            <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
          </template>
          <template #filter-name="{ column, $panel }">
            <div
              v-for="(option, index) in column.filters"
              :key="index"
              class="flex item-center justify-center"
            >
              <input
                type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                >
                  {{ option.label }}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
import CloudFun, { computed, defineComponent, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import CheckBoxList from '@/cloudfun/components/CheckBoxList.vue'

export default defineComponent({
  components: {
    Grid,
    CheckBoxList
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [{ field: 'Name' }, { field: 'Description' }]

    const gridOptions: GridOptions = {
      id: 'role',
      title: '角色',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: {
        sheetName: '角色清單',
        columns: printColumns,
        modes: ['current', 'selected', 'all']
      },
      exportConfig: {
        filename: '角色清單',
        type: 'csv',
        types: ['html', 'csv'],
        mode: 'all',
        modes: ['current', 'selected', 'all'],
        columns: printColumns
      },
      columns: [
        {
          field: 'Name',
          title: '名稱',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: '$input',
            immediate: true,
            attrs: { type: 'text' }
          },
          filters: [{ checked: false, label: '包含' }],
          slots: { filter: 'filter-name' }
        },
        {
          field: 'Description',
          title: '說明',
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
          editRender: {
            name: '$input',
            immediate: true,
            attrs: { type: 'text' }
          }
        },
        {
          field: 'PermissionValues',
          visible: false
        }
      ],
      promises: {
        query: model
          ? (params) => model.dispatch('role/query', params)
          : undefined,
        queryAll: model ? () => model.dispatch('role/query') : undefined,
        save: model
          ? (params) => model.dispatch('role/save', params)
          : undefined
      },
      modalConfig: { showFooter: true }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 60,
      titleAlign: 'right',
      items: [
        {
          field: 'Name',
          title: '名稱',
          span: 24,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', clearable: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'Description',
          title: '說明',
          span: 24,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', clearable: true },
            attrs: { type: 'text' }
          }
        },
        {
          title: '權限',
          span: 24,
          slots: { default: 'form-permissions' }
        },
      ],
      rules: {
        Name: [{ type: 'string', required: true }]
      }
    }

    const permissionItems = computed(() => {
      const items: any[] = []
      for (const permission of Object.values(model?.enums.SystemPermission || {})) {
        if (permission.GroupName != null) {
          let group = items.find(e => e.name === permission.GroupName)
          if (!group) items.push(group = { name: permission.GroupName, items: [], order: 0 })
          group.items.push({ name: permission.Name, value: permission.Value, order: permission.Order })
          if (group.order < permission.Order) group.order = permission.Order
        } else items.push({ name: permission.Name, value: permission.Value, order: permission.Order })
      }
      return items.sort((a: any, b: any) => a.order - b.order)
    })

    return {
      grid,
      gridOptions,
      formOptions,
      permissionItems
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
</script>
